<template>
  <div class="page-content-inner pt-5 pr-lg-0">
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="section-header pb-0 mt-2 uk-width-4-4@m">
          <div class="section-header-left">
            <h3 class="uk-text-truncate"><i class="uil-chart-line"></i> {{ $t("sideNav.My_statistics") }} </h3>
          </div>
        </div>
        <div class="uk-grid-margin uk-child-width-expand@s uk-text-center" uk-grid>
          <template v-if="dashboardLoading">
            <div>
              <div class="course-card uk-card-hover foruser">
                <div class="course-card-body">
                  <default-loading />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div>
              <div class="course-card uk-card-hover foruser">
                <div class="course-card-body">
                  <i class="uil-book-open"></i>
                  <h3>{{ dashboard_item.assigned_educations_count }}</h3>
                  <h4>{{ $t("home.education_assigned") }}</h4>
                </div>
              </div>
            </div>
            <div>
              <div class="course-card uk-card-hover foruser">
                <div class="course-card-body">
                  <i class="uil-presentation-check "></i>
                  <h3>{{ dashboard_item.completed_educations_count }}</h3>
                  <h4>{{ $t("home.i_completed_education") }} </h4>
                </div>
              </div>
            </div>
            <div v-if="this.$isGiftVisible">
              <div class="course-card uk-card-hover foruser">
                <div class="course-card-body">
                  <i class="uil-gift"></i>
                  <h3>{{ dashboard_item.gifts_count }}</h3>
                  <h4>{{ $t("home.i_won_a_gift") }}</h4>
                </div>
              </div>
            </div>
            <div>
              <div class="course-card uk-card-hover uk-card-primary foruser">
                <div class="course-card-body">
                  <i class="uil-coins"></i>
                  <h3>{{ dashboard_item.points_count }} / {{ dashboard_item.coins_count }}</h3>
                  <h4>{{ $t("home.point_coin") }}</h4>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="uk-width-5-5">
        <div uk-grid>
          <div class="uk-width-2-5@l uk-width-5-5">
            <div class="uk-card-default rounded uk-card-hover mt-3">
              <div class="course-card-body">
                <div class="uk-card-header pb-2 pt-0 pl-0 pr-0">
                  <div class="section-header-left">
                    <h4 class="uk-text-truncate"><i class="uil-trophy"></i> {{ $t("general.my_ranking") }} </h4>
                  </div>
                </div>

                <div class="pt-3 pb-0 text-center" v-if="leaderboardLoading">
                  <default-loading />
                </div>

                <div v-else class="pt-1 pb-0 text-center">
                  <ul id="leadership" class="uk-list-divider mt-2">
                    <li class="d-flex justify-content-between align-items-center yourself">
                      <div class="uk-width-auto position-relative p-1">
                        <img :src="leader_board.user?.photo_link" class="rounded-circle avatar-sm">
                        <span>{{ getCurrentUserPosition() }} {{ leader_board.user?.fullname }}</span>
                      </div>

                      <span>{{ dashboard_item.points_count }} {{ $t("point.point") }}</span>
                    </li>
                    <template v-for="(user_point, index) in leader_board.result">
                      <li :class="{ active: user_point.id == leader_board.user?.id }"
                        class="d-flex justify-content-between align-items-center">
                        <span>
                          <div class="line">{{ index + 1 }}</div><i class="uil-user"></i> {{
                            user_point.fullname
                          }}
                        </span>
                        <span>{{ user_point.points_sum_point ? user_point.points_sum_point : 0 }} {{ $t("point.point")
                        }}</span>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="uk-width-3-5">
            <div class="uk-card-default rounded uk-card-hover mt-3">
              <div class="course-card-body">
                <div class="uk-card-header pb-2 pt-0 pl-0 pr-0">
                  <div class="section-header-left">
                    <h4 class="uk-text-truncate"><i class="uil-coins"></i>{{ $t("collectionDetail.share_history") }}
                    </h4>
                  </div>
                </div>

                <div class="d-flex justify-content-center" v-if="userPointsLoading">
                  <default-loading />
                </div>
                <!-- Table -->
                <div v-else class="table-responsive mt-3 mb-0">
                  <table class="table align-items-center table-borderless table-sm">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t("general.activity") }}</th>
                        <th scope="col">{{ $t("general.points_i_earned") }}</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody class="list">
                      <tr v-for="(item, index) in user_points" :key="'user_point_history_item_' + index">
                        <th scope="row">
                          <div class="media align-items-start">
                            <div class="media-body">
                              <a class="name mb-0 text-sm"><i
                                  :class="item.point_category.icon ? item.point_category.icon : 'uil-award'"
                                  class="mr-1"></i>{{
                                    $t(item.point_category.key_name) }}</a>

                            </div>
                          </div>
                        </th>
                        <td class="point"> {{ item.point }} {{ $t("gift.point") }}</td>
                        <td class="text-right">
                          <!-- Actions -->
                          <div class="actions ml-3">
                            <button :data-id="item.id" class="uk-button uk-button-default uk-button-sm"
                              href="#modal-overflow" type="button" uk-toggle @click="setPointId">
                              {{ $t("general.details") }} <i class="uil-angle-right"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- pagination menu -->
                  <pagination v-if="point_filter.pagination && point_filter.pagination.hasOwnProperty('links')"
                    :pagination="point_filter.pagination" @changePage="setPage($event)"></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div uk-grid>
          <div class="uk-width-2-5@m">
            <div class="course-card uk-card-hover mb-4">
              <div class="course-card-body">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-chart-pie"></i> {{
                    $t("general.types_of_training_assigned_to_me")
                  }}</h4>
                </div>
                <div class="d-flex justify-content-center">
                  <template v-if="educationReportLoading">
                    <default-loading />
                  </template>
                  <template v-else>
                    <apexchart :options="apexEducationOptions.chartOptions" v-if="apexEducationOptions"
                      :series="apexEducationOptions.series" type="pie" width="440" />
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-3-5@m">
            <div class="course-card uk-card-hover mb-4">
              <div class="course-card-body">
                <div class="section-header-left">
                  <h4 class="uk-text-truncate"><i class="uil-calendar-alt"></i> {{
                    $t("general.points_i_have_gained_in_the_last_3_months") }}</h4>
                </div>
                <div class="d-flex justify-content-center">
                  <template v-if="pointReportLoading">
                    <default-loading />
                  </template>
                  <template v-else>
                    <apexchart v-if="apexPointOptions" :options="apexPointOptions.chartOptions"
                      :series="apexPointOptions.series" type="pie" width="605" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="modal-overflow" uk-modal>
          <div class="uk-modal-dialog uk-modal-dialog-large">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-header">
              <h3 class="uk-modal-title"> {{ $t("general.point_detail") }}</h3>
            </div>
            <div class="uk-modal-body p-3 pt-0">
              <div class="table-responsive border-0">
                <table class="table align-items-center">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t("general.activity") }}</th>
                      <th scope="col">{{ $t("general.points_i_earned") }}</th>
                      <th scope="col">{{ $t("general.detail") }}</th>
                      <th scope="col">{{ $t("general.date") }}</th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr v-for="(item, index) in user_point_detail" :key="'user_point_detail_history_item_' + index">
                      <th scope="row">
                        <div class="media align-items-start">
                          <div class="media-body">
                            <a class="name h6 mb-0 text-sm" href="#"><i
                                :class="item.point_category.icon ? item.point_category.icon : 'uil-award'"
                                class="mr-1"></i>{{
                                  $t(item.point_category.key_name)
                                }}</a>

                          </div>
                        </div>
                      </th>
                      <td> {{ item.point }} <i class="uil-coins"></i></td>
                      <td>
                        {{ item.pointable.title }}
                      </td>
                      <td>
                        {{ fixDate(item.created_at) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { GET_ITEMS } from "@/core/services/store/REST.module";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/az';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Pagination from "@/assets/components/Pagination";
import VueApexCharts from 'vue-apexcharts'
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: 'aes', isCompression: true });
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)

export default {
  name: "MyStatistics",
  components: { DefaultLoading, Pagination, SecureLS },
  data() {
    return {
      educationReportData: [],
      pointReportData: [],
      educationReportLoading: false,
      pointReportLoading: false,
      dashboardUrl: 'api/dashboard',
      dashboard_item: [],
      leaderBoardUrl: 'api/dashboard/leader-board',
      leader_board: [],
      leaderboardLoading: false,
      userPointUrl: 'api/user-point',
      userPointsLoading: false,
      dashboardLoading: false,
      user_points: [],
      userLastPointUrl: 'api/last-points',
      userEducationTypeUrl: 'api/user-education-type',
      user_last_points: [],
      userPointDetailUrl: 'api/user-point-detail',
      user_point_detail: [],
      point_id: "",
      user_id: "",
      current_user: [],
      point_filter: {
        pagination: null,
        page: 1,
        perPage: 5,
      },
      point_last_filter: {
        pagination: null,
        page: 1,
        perPage: 5,
      }
    }
  },
  computed: {
    apexEducationOptions() {
      let chartData = this.educationReportData;

      if (!chartData || !chartData.length) return null;

      let data = [];
      let labels = [];

      chartData.forEach(item => {
        labels.push(item.data);
        data.push(item.count);
      })
      return {
        series: data,
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          colors: ["#ae71ff", "#3e416d", "#e6e7e9", "#36b37e", "#e87a60", "#4572A7", "#AA4643", "#89A54E", "#80699B", "#3D96AE",
            "#DB843D", "#92A8CD", "#A47D7C", "#B5CA92"],
          labels: labels,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      }
    },
    apexPointOptions() {
      let chartData = this.pointReportData;

      if (!chartData || !chartData.length) return null;
      let data = [];
      let labels = [];

      chartData.forEach(item => {
        labels.push(item.data);
        data.push(item.count);
      })
      return {
        series: data,
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          colors: ["#ae71ff", "#3e416d", "#e6e7e9", "#36b37e", "#e87a60", "#4572A7", "#AA4643", "#89A54E", "#80699B", "#3D96AE",
            "#DB843D", "#92A8CD", "#A47D7C", "#B5CA92"],
          labels: labels,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      }
    },
  },
  methods: {
    fixDate(value) {
      return moment(value).locale(this.secureLocalStorage("language")).format('DD MMMM YYYY');
    },
    setPointId(e) {
      this.point_id = e.target.getAttribute('data-id')
      if (this.point_id) {
        this.$store.dispatch(GET_ITEMS, {
          url: this.userPointDetailUrl + '/' + this.point_id,
          acceptPromise: false,
        }).then(result => {
          if (result.status) {
            this.user_point_detail = result.data
          }
        })
      }
    },
    getDashboardData() {
      let self = this;
      self.dashboardLoading = true;
      self.$store.dispatch(GET_ITEMS, {
        url: self.dashboardUrl,
        filters: {
          userId: this.user_id
        },
        acceptPromise: false,
      }).then(result => {
        if (result.status) {
          self.dashboard_item = result.data
        }
        self.dashboardLoading = false;
      })
    },
    getLeaderBoardData() {
      if (ls.get('moduleList')?.dashboard.find((element) => element.type === 'leader_table')?.status !== 1)
        return false
      let self = this;
      self.leaderboardLoading = true;

      self.$store.dispatch(GET_ITEMS, {
        url: self.leaderBoardUrl,
        filters: {
          userId: this.user_id
        },
        acceptPromise: false,
      }).then(result => {
        if (result.status) {
          self.leader_board = result.data;
        }
        self.leaderboardLoading = false;
      })
    },
    getCurrentUserPosition() {
      return this.leader_board.userIndex;
    },
    getUserPoint() {
      let self = this;
      self.userPointsLoading = true;
      self.$store.dispatch(GET_ITEMS, {
        url: self.userPointUrl,
        filters: {
          page: self.point_filter.page,
          perPage: self.point_filter.perPage,
          userId: this.user_id,
        },
        acceptPromise: false,
      }).then(result => {
        if (result.status) {
          self.user_points = result.data.data
          self.point_filter.pagination = result.data
        }
        self.userPointsLoading = false;
      })
    },
    getUserLastPoint() {
      let self = this;
      self.pointReportLoading = true;
      self.$store.dispatch(GET_ITEMS, {
        url: self.userLastPointUrl,
        filters: {
          userId: this.user_id
        },
        acceptPromise: false,
      }).then(result => {
        if (result.status) {
          let items = result.data;
          let results = [];
          items.forEach(subItem => {
            results.push({
              count: Number(subItem.sum),
              data: this.$t(subItem.point_category.key_name),
            })
          })
          self.pointReportData = results;
        }
        self.pointReportLoading = false;
      })
    },
    getUserEducationTypes() {
      let self = this;
      self.educationReportLoading = true;
      self.$store.dispatch(GET_ITEMS, {
        url: self.userEducationTypeUrl,
        filters: {
          userId: this.user_id
        },
        acceptPromise: false,
      }).then(result => {
        if (result.status) {
          let data = result.data;
          let results = [];

          results.push({
            count: Number(data.trainings_count),
            data: self.$t('general.training')
          }, {
            count: Number(data.assignments_count),
            data: self.$t('general.assignments')
          })

          self.educationReportData = results;
        }
        self.educationReportLoading = false;
      })
    },
    sortOrder(sort, order) {
      let self = this;
      self.point_filter.page = 1;
      self.point_filter.sort = sort;
      self.point_filter.order = order;
      this.getUserPoint();
    },
    setPage(page) {
      let self = this;
      self.point_filter.page = page;
      this.getUserPoint();
    }
  },
  mounted() {
    this.user_id = this.$route.params.user;
    this.getDashboardData()
    this.getUserPoint()
    this.getUserLastPoint();
    this.getLeaderBoardData()
    this.getUserEducationTypes()
    this.current_user = JSON.parse(this.secureLocalStorage("user"))
  }
}
</script>

<style scoped></style>
<style>
/* .uk-button {
  background-color: var(--backgroundColor);
} */
</style>